import React from 'react';
import Card from '../components/Card';
import PropTypes from '../PropTypes';
import PostListing from '../components/PostListing';
import Layout from '../components/Layout';

const propTypes = {
  data: PropTypes.shape({
    cover: PropTypes.fileImage.isRequired,
    posts: PropTypes.object.isRequired,
    featuredPost: PropTypes.object.isRequired
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.any.isRequired
};

function IndexPage({ data, location }) {
  const postEdges = data.posts.edges;
  const featuredPost = data.featuredPost.data.value.uid;

  return (
    <Layout location={location}>
      <PostListing postEdges={postEdges} featuredPost={featuredPost} />
      <Card
        post={{
          slug: 'twin-peaks',
          title: 'Твин Пикс',
          originalTitle: 'Twin Peaks',
          description: 'Вспоминаем незабываемый сон',
          cover: { localFile: data.cover }
        }}
        className="container"
      />
    </Layout>
  );
}

IndexPage.propTypes = propTypes;

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery($postsFilter: PrismicPostFilterInput!) {
    cover: file(relativePath: { eq: "twin-peaks/welcome2_cut.png" }) {
      childImageSharp {
        sizes(maxWidth: 800, quality: 85, toFormat: JPG) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    featuredPost: prismicFeaturedPost {
      data {
        value {
          uid
        }
      }
    }
    ...allPosts
  }
`;
